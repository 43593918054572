.question ol {
    list-style-type: lower-alpha;
}

#UploadTest img {
    max-width: 400px;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Messages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 0.5em;
    align-items: baseline;
    justify-content: space-around;
    grid-template-rows: 5em 80vh;
}

#Messages>h2 {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: center;
}

#Messages section {
    border: solid deepskyblue;
    border-radius: 0.5em;
    align-self: stretch;
    padding-left: 0.3em;
    padding-right: 0.3em;
    overflow: auto;
}

#SendMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#SendMessage textarea {
    min-width: 80%;
    margin-bottom: 1em;
}

.mdRender img {
    max-width: 100%;
}